<template>
    <v-timeline-item
      :key="log.id"
      :color="color"
      dark
      fill-dot
    >
      <v-card
        dark
        :color="color"
        class="mb-4"
        outlined
      >
        <v-card-title class="body-1">{{ time }}</v-card-title>

        <v-card-text class="white body-2 grey--text py-2">

          <ul>
            <li>{{'data.status'| t}}: {{statusLabel}}</li>
          </ul>
        </v-card-text>

        <v-card-actions class="white">
          <v-btn
            v-if="params"
            color="grey darken-2"
            outlined
            class="white--text mr-4"
            small
            @click="viewParams"
          >
            {{'ec.action.view_delivery_request_params'| t}}
          </v-btn>

          <v-btn
            v-if="response"
            color="grey darken-2"
            outlined
            class="white--text"
            small
            @click="viewResponse"
          >
            {{'ec.action.view_delivery_request_response'| t}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-timeline-item>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    log: Object,
  },
  methods: {
    viewParams() {
      this.$apopup.base({
        width: '600px',
        title: this.$t('ec.action.view_delivery_request_params'),
        bodySlot: () => import('modules/order/views/orderForm/formLayer/logResponse.vue'),
        response: this.params,
      })
    },
    viewResponse() {
      this.$apopup.base({
        width: '600px',
        title: this.$t('ec.action.view_delivery_request_response'),
        bodySlot: () => import('modules/order/views/orderForm/formLayer/logResponse.vue'),
        response: this.response,
      })
    },
  },
  computed: {
    color() {
      if(this.isSuccess) return 'green  lighten-2'
      return 'red lighten-2'
    },
    isSuccess() {
      return this.log.status == true
    },
    statusLabel() {
      if(this.isSuccess) return this.$t('data.status.success')
      return this.$t('data.status.failure')
    },
    time() {
      return this.$dayjs(this.log.created_at).format('YYYY/MM/DD HH:mm:ss')
    },
    params() {
      return this.log.params
    },
    response() {
      return this.log.response
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
